import '../css/app.scss';

import Header from './components/header';
import Footer from './components/footer';

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}


$(() => {
	const $header = $('[data-header="el"]');
	if ($header.length) {
		new Header($header);
	}

	const $footer = $('[data-footer="el"]');
	if ($footer.length) {
		new Footer($footer);
	}

	const $hero = $('[data-hero="el"]');
	if ($hero.length) {
		// dynamically import the Hero class
		import(/* webpackChunkName: "hero" */ './components/hero').then(({ default: Hero }) => {
			new Hero($hero);
		});
	}

	const $appartments = $('[data-appartment="el"]');
	if($appartments.length) {
		// dynamically import the Appartment class
		import(/* webpackChunkName: "appartment" */ './components/appartment').then(({ default: Appartment }) => {
			$appartments.each((index, el) => {
				new Appartment($(el));
			});
		});
	}

	const $tiles = $('[data-tiles="el"]');
	if($tiles.length) {
		// dynamically import the Tiles class
		import(/* webpackChunkName: "tiles" */ './components/tiles').then(({ default: Tiles }) => {
			$tiles.each((index, el) => {
				new Tiles($(el));
			});
		});
	}

	const $imageTextSlider = $('[data-image-text-slider="el"]');
	if($imageTextSlider.length) {
		// dynamically import the ImageTextSlider class
		import(/* webpackChunkName: "imageTextSlider" */ './components/imageTextSlider').then(({ default: ImageTextSlider }) => {
			$imageTextSlider.each((index, el) => {
				new ImageTextSlider($(el));
			});
		});
	}

	const $location = $('[data-location="el"]');
	if($location.length) {
		// dynamically import the Location class
		import(/* webpackChunkName: "location" */ './components/location').then(({ default: Location }) => {
			$location.each((index, el) => {
				new Location($(el));
			});
		});
	}



	
});
