class Nav {
  constructor($el) {
    this.$el = $el;
    this.$links = $el.find('[data-nav="link"]');
    this.$img = $el.find('[data-nav="img"]');
    this.defaultImg = $el.data('img');
    this.init();
  }

  init = () => {
    this.$links.on('mouseenter', this.handleMouseEnter);
    this.$links.on('mouseleave', this.handleMouseLeave);
  }

  handleMouseEnter = (e) => {
    const $currentTarget = $(e.currentTarget);
    const img = $currentTarget.data('img');

    this.$img.attr('src', img);
  }

  handleMouseLeave = () => {
    this.$img.attr('src', this.defaultImg);
  }
}

export default Nav;