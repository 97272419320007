import Nav from './nav';
import { throttle } from 'lodash';

class Header {
	stateClasses = {
		menuOpen: 'menu--open',
		hidden: 'header--hidden',
		background: 'header--background',
	};

	constructor($el) {
		this.$el = $el;
		this.$nav = $el.find('[data-nav="el"]');
		this.$html = $('html');
		this.$hamburger = $el.find('[data-header="hamburger"]');
		this.isOpen = false;
		this.$window = $(window);

		this.offset = $el.hasClass('header--home') ? this.$window.height() : 0;
		this.lastScrollTop = this.offset;

		this.init();
	}

	init = () => {
		this.$hamburger.on('click', this.toggleMenu);

		if (this.$nav) {
			this.nav = new Nav(this.$nav);
		}

		// only
		$(window).on('scroll', throttle(this.handleScroll, 100));
	};

	toggleMenu = () => {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	};

	open = () => {
		this.$html.addClass(this.stateClasses.menuOpen);
		this.isOpen = true;

		// bind escape key
		$(document).on('keydown', this.close);
	};

	close = () => {
		this.$html.removeClass(this.stateClasses.menuOpen);
		this.isOpen = false;

		// unbind escape key
		$(document).off('keydown', this.close);
	};

	handleScroll = () => {
		// check if the user is scrolling down
		const { offset, stateClasses, $el } = this;
		const scrollTop = this.$window.scrollTop();

		// console.log('scrollTop', scrollTop);

		if (scrollTop > this.lastScrollTop && scrollTop > offset) {
			// user is scrolling down
			$el.addClass(stateClasses.hidden);
			$el.removeClass(stateClasses.background);
		} else {
			// user is scrolling up
			$el.removeClass(stateClasses.hidden);

			if(scrollTop > offset) {
			  $el.addClass(stateClasses.background);
			} else {
			  $el.removeClass(stateClasses.background);
			}
		}

		this.lastScrollTop = scrollTop;
	};
}

export default Header;
