class Footer {
  constructor($el) {
    this.$el = $el;
    this.$contact = $el.find('[data-footer="contact"]');
    this.$close = $el.find('[data-footer="close"]');
    this.$flyout = $el.find('[data-footer="flyout"]');
    this.init();
  }

  init = () => {
    this.$contact.on('click', this.showDialog);
    this.$close.on('click', this.closeDialog);
  }

  showDialog = () => {
    this.$flyout[0].showModal();
  };

  closeDialog = () => {
    this.$flyout[0].close();
  };
}

export default Footer;